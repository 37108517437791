'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  LoginWithUserAndPasswordSchema,
  loginWithUserAndPassword,
} from '@repo/bff/src/schemas/auth.schema';
import { useLanguage } from '@repo/i18n';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@ui/components/ui/form';
import { Input } from '@ui/components/ui/input';
import { useForm } from 'react-hook-form';
import { PasswordInput } from './PasswordInput';

export const LoginForm = ({
  formId,
  onSubmit,
}: {
  formId: string;
  onSubmit: (data: LoginWithUserAndPasswordSchema) => void;
}) => {
  const { dictionary } = useLanguage();

  const form = useForm<LoginWithUserAndPasswordSchema>({
    resolver: zodResolver(loginWithUserAndPassword),
    defaultValues: {},
  });

  const password = form.watch('password');

  console.log(form.formState.errors);

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} id={formId}>
          <div className='flex flex-col space-y-4'>
            <FormField
              control={form.control}
              name='email'
              defaultValue=''
              render={({ field }) => (
                <FormItem>
                  <FormLabel className='capitalize'>
                    {dictionary.login.email}
                  </FormLabel>
                  <FormControl>
                    <Input type='email' className='' {...field} />
                  </FormControl>
                  <FormDescription />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='password'
              render={() => (
                <FormItem>
                  <FormLabel className='capitalize'>
                    {dictionary.login.password}
                  </FormLabel>
                  <FormControl>
                    <PasswordInput
                      id='password'
                      value={password}
                      onChange={(e) => {
                        form.setValue('password', e.target.value);
                      }}
                      autoComplete='password'
                    />
                    {/* <Input type="password" className="" {...field} /> */}
                  </FormControl>
                  <FormDescription />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>
    </div>
  );
};

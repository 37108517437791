'use client';

import { useToast } from '@ui/components/ui/use-toast';
import { useCallback, useState } from 'react';
import { LoginWithUserAndPasswordSchema } from '@repo/bff';
import { loginUser } from '../lib/loginUser';
import { useLanguage } from '@repo/i18n';
import { useRouter } from 'next/navigation';
import { useApiError } from '../hooks/useApiError';
import { loginUserByIdp } from '../lib/loginUserByIdp';
import { UserType } from 'database';
import { CustomLogin } from './CustomLogin';
import { GoogleAuthLayout } from './GoogleAuthLayout';

export const LoginBody = () => {
  const { lang, dictionary } = useLanguage();

  const { handleError } = useApiError();

  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const { toast } = useToast();

  const handleResponse = (res: { user_type: UserType }) => {
    toast({
      title: dictionary.login.success,
      variant: 'default',
    });

    if (res.user_type === 'cannabis_consumer') {
      router.replace(`/${lang}/user/profile`);
      return;
    }

    if (res.user_type === 'doctor') {
      router.replace(`/${lang}/patients`);
      return;
    }
  };

  const onSubmitWithUserAndPassword = useCallback(
    (data: LoginWithUserAndPasswordSchema) => {
      setIsLoading(true);

      loginUser({
        body: data,
      })
        .then((res) => {
          return handleResponse(res);
        })
        .catch((err) => {
          if (err.json.code === 'not_allowed_doctor') {
            toast({
              title: 'Proximamente se habilitará el acceso para médicos',
              variant: 'default',
            });
          } else {
            handleError(err);
          }
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return (
    <GoogleAuthLayout
      onLoginSuccess={loginUserByIdp}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    >
      <CustomLogin
        onSubmitWithUserAndPassword={onSubmitWithUserAndPassword}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </GoogleAuthLayout>
  );
};

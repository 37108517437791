'use client';

import { CustomText } from '@repo/ui/custom/customText';
import { GoogleIcon } from '@repo/ui/custom/GoogleIcon';
import { Button } from '@ui/components/ui/button';
import { cn } from '@ui/lib/utils';
import { signIn } from 'next-auth/react';

export const GoogleBtn = (props: {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  small?: boolean;
  label: string;
}) => {
  const handleOnClick = () => {
    signIn('google');
  };

  return (
    <Button
      disabled={props.isLoading}
      variant={'default'}
      className='w-full h-1/2'
      onClick={handleOnClick}
    >
      <div className={cn('flex flex-row md:space-x-4 space-x-2')}>
        <GoogleIcon className='md:min-w-8 min-w-7' />
        <div className='flex items-center'>
          <CustomText className=''>{props.label}</CustomText>
        </div>
      </div>
    </Button>
  );
};

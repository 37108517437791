'use client';

import Link from 'next/link';
import { LoginWithUserAndPasswordSchema } from '@repo/bff';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@ui/components/ui/card';
import { Button } from '@ui/components/ui/button';
import { useLanguage } from '@repo/i18n';
import { LoginForm } from '@repo/ui/custom/LoginForm';
import { CustomText } from '@repo/ui/custom/customText';
import { Separator } from '@repo/ui/components/ui/separator';
import { GoogleBtn } from './GoogleSignInButton';

export const CustomLogin = ({
  onSubmitWithUserAndPassword,
  isLoading,
  setIsLoading,
}: {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  onSubmitWithUserAndPassword: (body: LoginWithUserAndPasswordSchema) => void;
}) => {
  const { dictionary, lang } = useLanguage();

  const formId = 'login-form';

  return (
    <Card className='md:px-5 py-5 w-full'>
      <CardHeader>
        <CardTitle>
          <div className='flex flex-row justify-between'>
            <div>{dictionary.login.signIn}</div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className='flex flex-col w-full'>
          <LoginForm onSubmit={onSubmitWithUserAndPassword} formId={formId} />
          <div className='py-4 w-auto'>
            <Button className='w-full' type='submit' form={formId}>
              <div>
                <CustomText>{dictionary.login.signIn}</CustomText>
              </div>
            </Button>
          </div>
          <Separator className='my-4' />
          <div className='flex flex-row py-2'>
            <GoogleBtn
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              label={dictionary.login.signInWithGoogle}
            />
          </div>
          <div className='pt-2 flex flex-col space-y-2'>
            <div className='text-gray-400 text-sm'>
              <Link
                className='hover:text-blue-400'
                href={`/${lang}/login/forgot-password`}
              >
                {dictionary.login.forgotPassword}
              </Link>
            </div>
            <div className='text-gray-400 text-sm'>
              <Link className='hover:text-blue-400' href={`/${lang}/register`}>
                {dictionary.login.not_have_account}
              </Link>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

import { httpClient } from "../http/client";
import { LoginUserResponseBody, LoginWithIdpSchema } from "@repo/bff";

export const loginUserByIdp = async (
  {
    body,
    cookie,
  }: {
    cookie?: string,
    body: LoginWithIdpSchema
  }
): Promise<LoginUserResponseBody> =>
  httpClient
    .headers(
      cookie
        ? {
          cookie,
        }
        : {}
    )
    .post(body, '/v1/auth/login/idp')
    .json()